/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */

/* ----------------------------------------------------------------------------------------------------- */
/*  styles for navigation
/* ----------------------------------------------------------------------------------------------------- */
.fuse-horizontal-navigation-item {
  padding: 0 12px !important;
}

.fuse-horizontal-navigation-item-icon {
  margin-right: 6px !important;
}

/* ----------------------------------------------------------------------------------------------------- */
/*  styles for breadcrumbs
/* ----------------------------------------------------------------------------------------------------- */

.breadcrumbs {
  @apply hidden sm:block;
}

.xng-breadcrumb-root {
  @apply font-medium;
  @apply ml-0.5 pb-2;
}

.xng-breadcrumb-link {
  @apply hover:text-accent-800 dark:hover:text-primary;
  @apply active:text-primary;
}

/* ----------------------------------------------------------------------------------------------------- */
/*  styles for icon and text buttons
/* ----------------------------------------------------------------------------------------------------- */

.app-button-icon {
  @apply icon-size-5;
}

.app-button-text {
  @apply mr-1 ml-1;
}

.app-menu-button-icon {
  @apply icon-size-4;
}

.app-menu-button-text {
  @apply mr-0;
}

/* ----------------------------------------------------------------------------------------------------- */
/*  styles for content en list pages
/* ----------------------------------------------------------------------------------------------------- */

.app-content-container {
  @apply sm:absolute sm:inset-0 flex flex-col flex-auto min-w-0 overflow-auto;
}

/* ----------------------------------------------------------------------------------------------------- */
/*  styles header section
/* ----------------------------------------------------------------------------------------------------- */
.app-header-section {
  @apply relative flex items-center sm:flex-row flex-0 sm:justify-between py-4 px-6 md:px-8 border-b bg-card dark:bg-transparent;
}

.app-header-texts {
}

.app-text-secondary {
  color: rgb(113, 63, 18);
  @apply dark:text-white;
}

.app-header-title {
  @apply text-3xl font-bold tracking-tight leading-none;
  color: rgb(113, 63, 18);
  @apply dark:text-white;
  @apply flex items-center min-h-8;
}

.app-header-actions {
  @apply flex items-center sm:mt-0 sm:ml-4 justify-end grow;
}

.app-header-list-items-search {
  @apply min-w-64 hidden md:block #{!important};
}

.app-header-list-items-buttons {
  @apply mt-0;
}

.app-header-options {
  @apply hidden sm:inline-flex ml-4 #{!important};
}

.app-header-options-library {
  @apply ml-4 #{!important};
}

.app-mat-menu-button {
  @apply flex items-center #{!important};
}

.app-header-list-items-add {
  @apply sm:ml-2 #{!important};
}

/* ----------------------------------------------------------------------------------------------------- */
/*  styles for list page
/* ----------------------------------------------------------------------------------------------------- */
.app-list-container {
  @apply flex flex-col flex-auto overflow-hidden sm:overflow-y-auto px-6 py-6;
}

.app-simple-list-container {
  @apply flex flex-col flex-auto overflow-hidden sm:overflow-y-auto;
}

.app-list-activities {
  @apply flex flex-col flex-auto px-6 py-6;
}

.app-list-container-in-step {
  @apply flex flex-col flex-auto overflow-hidden sm:overflow-y-auto py-2 mb-4;
}

.app-list-subcontainer {
  @apply grid bg-card dark:bg-transparent drop-shadow-lg;
}

.app-list-subcontainer-modal {
  @apply grid bg-card dark:bg-transparent mt-5 border-solid border border-slate-200 #{!important};
}

.app-list-header {
  @apply z-10 sticky top-0 grid gap-4 py-4 px-6 md:px-8 shadow text-md font-semibold text-secondary bg-gray-50 dark:bg-black dark:bg-opacity-5;
}

.app-list-row {
  @apply grid items-center gap-4 py-2 px-6 md:px-8 border-b;
}

.app-list-actions {
  @apply flex justify-center;
}

.app-list-content-button {
  width: 32px !important;
  height: 32px !important;
  min-height: 32px !important;
  max-height: 32px !important;
}

/* ----------------------------------------------------------------------------------------------------- */
/*  styles for list side navigation
/* ----------------------------------------------------------------------------------------------------- */

.app-side-navigation-content-container {
  @apply flex flex-col w-full min-w-0 sm:absolute sm:inset-0 sm:overflow-hidden;
}

.app-side-navigation-drawer-container {
  @apply flex-auto sm:h-full;
}

.app-side-navigation-drawer {
  @apply sm:w-96 dark:bg-gray-900 #{!important};
}

.app-side-navigation-header {
  @apply flex items-center justify-between m-8 mr-6 sm:my-8;
}

.app-side-navigation-title {
  @apply text-4xl font-extrabold tracking-tight leading-none app-text-secondary;
}

.app-side-navigation-close-button {
  @apply lg:hidden;
}

.app-side-navigation-panel-links {
  @apply flex flex-col divide-y border-t border-b;
}

.app-side-navigation-panel {
  @apply flex px-8 py-5 cursor-pointer;
}

.app-side-navigation-selected-panel {
  @apply bg-primary-50 dark:bg-hover;
}

.app-side-navigation-not-selected-panel {
  @apply hover:bg-gray-100 dark:hover:bg-hover;
}

.app-side-navigation-selected-panel-icon {
  @apply text-hint;
}

.app-side-navigation-not-selected-panel-icon {
  @apply text-primary dark:text-primary-500;
}

.app-side-navigation-panel-content {
  @apply ml-3;
}

.app-side-navigation-panel-title {
  @apply font-medium leading-6;
}

.app-side-navigation-selected-panel-title {
  @apply text-primary dark:text-primary-500;
}

.app-side-navigation-panel-description {
  @apply mt-0.5 text-secondary;
}

.app-side-navigation-drawer-content {
  @apply flex flex-col;
}

.app-side-navigation-drawer-content-main {
  @apply flex-auto px-6 pt-9 pb-12 sm:p-12 sm:pt-4 lg:p-12 lg:pt-4;
}

.app-side-navigation-header-section {
  @apply relative flex items-center sm:flex-row flex-0 sm:justify-between;
}

.app-side-navigation-drawer-content-main-panel-header {
  @apply flex items-center;
}

.app-side-navigation-drawer-content-toggle {
  @apply lg:hidden -ml-2 #{!important};
}

.app-side-navigation-drawer-content-title {
  @apply ml-2 lg:ml-0 text-3xl font-bold tracking-tight leading-none;
}

.app-side-navigation-drawer-content-panels {
  @apply mt-4;
}

/* ----------------------------------------------------------------------------------------------------- */
/*  styles for mat paginator
/* ----------------------------------------------------------------------------------------------------- */
.app-mat-paginator {
  @apply flex justify-end bg-gray-50 dark:bg-transparent;
  //@apply sm:absolute sm:inset-x-0 sm:bottom-0 border-b sm:border-t sm:border-b-0 z-10 bg-gray-50 dark:bg-transparent
}

.mat-mdc-paginator .mat-mdc-paginator-container {
  padding: 4px 16px 8px 16px !important;
}

.mat-mdc-paginator .mat-mdc-paginator-container .mat-mdc-paginator-page-size {
  margin: 0px !important;
  @apply flex justify-center w-full md:flex-none md:w-auto;
}

.mat-mdc-paginator .mat-mdc-paginator-container .mat-mdc-paginator-range-actions {
  margin: 0px 0 !important;
}

.mat-mdc-paginator-range-label {
  margin: 0 12px 0 24px;
}

.mat-mdc-paginator {
  background: transparent !important;
}

/* ----------------------------------------------------------------------------------------------------- */
/*  styles for list cards page
/* ----------------------------------------------------------------------------------------------------- */

.app-cards-container {
  @apply flex-auto p-6 sm:p-10;
}

.app-cards-subcontainer {
  @apply grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-10;
}

.app-cards-card {
  @apply flex flex-col items-center max-w-80 w-full p-8;
}

.app-cards-icon-container {
  @apply flex items-center justify-center p-5 rounded-full bg-primary-100 dark:bg-gray-600;
}

.app-cards-card-icon {
  @apply icon-size-10 text-primary-500 dark:text-primary-300;
}

.app-cards-card-title {
  @apply text-2xl font-semibold leading-tight text-center mt-6;
}

.app-cards-card-description {
  @apply text-center text-secondary mt-3;
}

.app-cards-card-button {
  @apply px-6 mt-8 #{!important};
}

/* ----------------------------------------------------------------------------------------------------- */
/*  styles for simple form page
/* ----------------------------------------------------------------------------------------------------- */

.app-form-container {
  @apply flex-auto p-4 sm:p-6;
}

.app-form-card {
  @apply flex flex-col bg-card shadow rounded-md px-8 pt-6 pb-6 mb-6;
}

.app-form-card-title {
  @apply pb-2 rounded-t flex items-center;
}

.app-form-card-title-icon {
  @apply icon-size-5 flex-none;
}

.app-form-card-title-text {
  @apply text-lg font-medium flex-none ml-2;
}

.app-form-field-group {
  @apply grid grid-cols-1 md:grid-cols-2 gap-x-8 gap-y-6 mt-5;
}

.app-form-related-files {
  @apply grid grid-cols-1 gap-x-8 mt-10;
}

.app-form-buttons {
  @apply flex items-center justify-end mt-0 mb-0;
}

.app-form-buttons-cancel {
}

/* Adjust the border color of outlined buttons */
.app-form-buttons > .mat-mdc-outlined-button,
.content-acction-button-confirm-modal > .mat-mdc-outlined-button {
  /* Not disabled */
  &:not([disabled='true']) {
    @apply border-primary dark:border-white #{'!important'};
  }
}

.mat-secondary {
  color: white !important;
  background-color: #9c580c !important;
}

.mat-mdc-snack-bar-container .mdc-snackbar__surface {
  max-width: 300px !important;
  min-width: 250px !important;
}

.app-form-buttons-save-new {
  @apply hidden sm:inline-flex px-6 ml-3 #{!important};
}

.app-form-buttons-save {
  @apply px-6 ml-3 #{!important};
}

.app-form-buttons-next {
  @apply inline-flex px-6 ml-3 #{!important};
}

.app-form-buttons-previous {
  @apply inline-flex px-6 ml-3 #{!important};
}

.app-single-field {
}

.app-single-field mat-form-field {
  @apply w-full;
}

.app-field-group {
  //@apply mb-3 #{!important};
  @apply grid gap-1 grid-cols-8;
}

.app-radio-group {
  //@apply mb-3 #{!important};
}

.app-radio-group mat-label {
  @apply font-medium flex items-center dark:text-white;
}

.app-radio-group mat-radio-group {
  @apply flex;
  margin-bottom: -0.6em !important;
}

.app-radio-group mat-radio-button {
  @apply flex-none mt-0 mb-0 mr-6;
}

.app-radio-group mat-error {
  @apply font-medium text-sm;
}

.app-form-marker-required {
  color: #dc2626;
  @apply mx-1;
}

.app-form-marker {
  @apply mx-1;
}

.app-form-icon-info {
  @apply icon-size-5;
}

mat-form-field {
  display: block !important;
}

/* ----------------------------------------------------------------------------------------------------- */
/*  styles for modal
/* ----------------------------------------------------------------------------------------------------- */

.app-modal-container {
  @apply relative flex flex-col w-full h-full;
}

.app-modal-title {
  @apply text-xl leading-6 font-medium w-full bg-gray-50 dark:bg-transparent;
}

.app-modal-header,
.app-modal-footer {
  @apply flex items-center justify-center sm:justify-end px-6 py-4 space-x-3 bg-gray-50 dark:bg-black dark:bg-opacity-10;
}

.app-modal-panel-small {
  @screen md {
    @apply w-128;
  }

  .mat-mdc-dialog-container .mdc-dialog__surface {
    padding: 0 !important;
  }

  .mdc-dialog .mdc-dialog__content {
    padding-top: 0px;
  }

  z-index: 1 !important;
}

.app-modal-panel-medium {
  @screen md {
    @apply w-9/12;
  }

  .mat-mdc-dialog-container .mdc-dialog__surface {
    padding: 0 !important;
  }

  .mdc-dialog .mdc-dialog__content {
    padding-top: 0px;
  }

  z-index: 1 !important;
}

.app-modal-panel-full {
  @screen md {
    @apply w-11/12;
  }

  .mat-mdc-dialog-container .mdc-dialog__surface {
    padding: 0 !important;
  }

  .mdc-dialog .mdc-dialog__content {
    padding-top: 0px;
  }

  z-index: 1 !important;
}

.app-modal-form-container {
  @apply flex-auto pl-4 pr-4 pb-4 pt-0 sm:pl-6 sm:pr-6 sm:pb-6 sm:pt-0;
}

.app-simple-list-container {
  @apply flex flex-col flex-auto overflow-hidden sm:overflow-y-auto;
}

.app-simple-list-subcontainer {
  @apply grid bg-card dark:bg-transparent border-solid border border-slate-200 #{!important};
}

.form-field-with-hint {
  @apply grid grid-cols-1 gap-x-8 gap-y-0;
}

/* ----------------------------------------------------------------------------------------------------- */
/*  styles for snack bar
/* ----------------------------------------------------------------------------------------------------- */
.app-snack-bar {
  padding: 0px !important;
  margin-top: 20px !important;
  --mdc-snackbar-container-color: transparent !important;
}

.app-snack-bar .mdc-snackbar__label {
  padding: 0px !important;
}

.app-snack-bar .mdc-snackbar__surface {
  padding-right: 0px !important;
}

/* ----------------------------------------------------------------------------------------------------- */
/*  complementary styles for fuse confirm dialog
/* ----------------------------------------------------------------------------------------------------- */

.mat-info {
  @apply bg-blue-600 text-white #{!important};
}

.mat-warning {
  @apply bg-amber-500 text-white #{!important};
}

.mat-neutral {
  @apply bg-slate-200 text-black #{!important};
}

.mat-warning .mat-button-wrapper {
  @apply drop-shadow-md;
}

/* ----------------------------------------------------------------------------------------------------- */
/*  complementary styles for fuse confirm dialog
/* ----------------------------------------------------------------------------------------------------- */

.general-link {
  @apply text-md font-medium text-primary-500 hover:underline;
}

/* ----------------------------------------------------------------------------------------------------- */
/*  Styles for custom hints in forms (It is not hidden when an error occurs in the field)
/* ----------------------------------------------------------------------------------------------------- */

.form-field-with-hint .mat-form-field-wrapper {
  margin-bottom: 0px !important;
}

.custom-hint {
  @apply flex text-sm	text-blue-600;
}

/* ----------------------------------------------------------------------------------------------------- */
/*  Styles for auth features
/* ----------------------------------------------------------------------------------------------------- */

.app-auth-button {
  @apply hidden sm:block px-6 ml-0 w-full #{!important};
}

/* ----------------------------------------------------------------------------------------------------- */
/*  Styles for stepper
/* ----------------------------------------------------------------------------------------------------- */

.app-stepper {
  @apply flex flex-col bg-card shadow rounded-md px-8 pt-0 pb-1 mb-6 pl-0 pr-0 md:pt-6 md:pl-6 md:pr-6;
}

/* ----------------------------------------------------------------------------------------------------- */
/*  Styles view option
/* ----------------------------------------------------------------------------------------------------- */

.mat-mdc-form-field.mat-form-field-appearance-fill.mat-form-field-disabled {
  opacity: 1 !important;
}

/* ----------------------------------------------------------------------------------------------------- */
/*  Styles for mat-select-filter
/* ----------------------------------------------------------------------------------------------------- */
/*
.mdc-menu-surface.mat-mdc-select-panel {
    padding: 0px 0 !important;
}
*/

.white-icon {
  color: white;
}

/* ----------------------------------------------------------------------------------------------------- */
/*  Styles for mat-select-filter
/* ----------------------------------------------------------------------------------------------------- */

.ngx-mat-timepicker form .ngx-mat-timepicker-table .ngx-mat-timepicker-tbody tr td .mat-mdc-form-field {
  width: 50px !important;
  max-width: 60px !important;
}

/* ----------------------------------------------------------------------------------------------------- */
/*  Styles for mat-select-filter
/* ----------------------------------------------------------------------------------------------------- */

.app-wizard-buttons-header {
  @apply flex items-center sm:mt-0 sm:ml-4 justify-end grow hidden sm:block;
}

.app-wizard-buttons-footer {
  @apply flex items-center fixed bottom-0 bg-neutral-50 border-t-2 border-t-neutral-200 shadow-2xl dark:border-gray-500 z-10 #{!important};
  width: -webkit-fill-available;
  width: -moz-available;
  app-wizard-buttons {
    @apply w-full bg-card;
    .app-form-buttons {
      @apply justify-between px-10 py-3;
    }
  }
}

/* ----------------------------------------------------------------------------------------------------- */
/*  Mat drawer styles
/* ----------------------------------------------------------------------------------------------------- */
.app-drawer-container {
  @apply flex-auto h-full bg-card dark:bg-transparent;
}

.app-drawer-content {
  @apply flex flex-col bg-gray-100 dark:bg-transparent;
}

.app-drawer {
  @apply w-full sm:w-100 dark:bg-gray-900 #{!important};
}

.app-drawer-title {
  @apply flex flex-row items-center px-4 h-16 min-h-16 bg-slate-100 bg-card dark:bg-opacity-10;
}

.app-drawer-title-text {
  @apply text-lg font-semibold tracking-tight text-secondary;
}

.app-drawer-close {
  @apply icon-size-5 text-current;
}

.app-drawer-body {
  @apply flex flex-col pb-4 px-4 pt-2 bg-card;
}

/* ----------------------------------------------------------------------------------------------------- */
/*  Mat expansion panel
/* ----------------------------------------------------------------------------------------------------- */

.app-mat-expansion-panel {
  @apply dark:bg-gray-700 #{!important};
}

.app-page-title,
.app-page-title * {
  @apply dark:text-white #{!important};
}

.app-page-mat-option.mat-mdc-option-active {
  @apply dark:text-white #{!important};
  .mdc-list-item__primary-text {
    @apply dark:text-white #{!important};
  }
}

:root {
  --swiper-navigation-color: black !important;
  --swiper-navigation-size: 20 !important;
}
